import React from "react";
import PageHeader from "../component/PageHeader";
import SData from "../dataApi/SData";
import ServiceInnerContentSec from "../component/ServiceInnerContentSec";
import {Helmet} from "react-helmet";




function InternetMarketing() {

  return (
    <>
    <Helmet>
        <title>Internet Marketing - Fennel Infotech</title>
        <meta name="description" content="Hire Shopify Experts, Wordpress Developer, Andrid and iOS App Developers" />
    </Helmet>

<PageHeader Ptitle="Online Marketing (SEO/SMO)" PSubhead="Fennel Infotech - is an emerging and promising online services provider company. " />

<ServiceInnerContentSec HeadingTitle={SData[2].HeadingTitle} Desc={SData[2].Desc} ImgSrc={SData[2].ImgSrc} Page={SData[2].Page} keys={SData[2].id} />


    </>
    
  );
}

export default InternetMarketing;