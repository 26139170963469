import React from "react";
import PageHeader from "../component/PageHeader";
import AboutPageContent from "../AboutPageContent"
import {Helmet} from "react-helmet";

function About() {
  return (
    <>
    <Helmet>
        <title>About - Fennel Infotech</title>
        <meta name="description" content="Hire Shopify Experts, Freelance Wordpress Developer, Andrid and iOS App Developers and Freelancers in NYC, Los Angeles, California and Texas" />
    </Helmet>

<PageHeader Ptitle="About Us" PSubhead="Fennel Infotech- is an emerging and promising online services provider company in NYC." />
<AboutPageContent />
    </>
    
  );
}

export default About;