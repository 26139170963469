import React from "react";
import AboutPageData from "./dataApi/AboutPageData";


const AbContent = (props) => {
    return(
        <div className="row">
                <div className="col-md-6 image_sec">
                    <img src={props.ImgSrc} className="w-100" />
                </div>
                <div className="col-md-6 content_sec">
                    <h3 className="about_heading">
                        {props.Heading}
                    </h3>
                    
                    <div dangerouslySetInnerHTML={{__html: `<div className="about_text"> ${props.Text} </div>`}}></div>
                </div>
            </div>
    )
}

const AboutPageContent = () => {
    return(
        <>
        
        <div className="container about_content">
           {AboutPageData.map(function(AboutVal){
               return(
                <AbContent ImgSrc={AboutVal.ImgSrc} Heading={AboutVal.Heading} Text={AboutVal.Text} keys={AboutVal.id} />
               )
               
           })}
        </div>
        
        </>
    )
}

export default AboutPageContent;