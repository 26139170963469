import React from "react";
import PageHeader from "../component/PageHeader";
import SData from "../dataApi/SData";
import ServiceInnerContentSec from "../component/ServiceInnerContentSec";
import {Helmet} from "react-helmet";



function WebsiteDevelopment() {

  return (
    <>
    <Helmet>
        <title>Website Development - Fennel Infotech</title>
        <meta name="description" content="Hire Shopify Experts, Wordpress Developer, Andrid and iOS App Developers" />
    </Helmet>

<PageHeader Ptitle="Website Development" PSubhead="Hire Shopify Experts, Wordpress Developer, Andrid and iOS App Developers" />

<ServiceInnerContentSec HeadingTitle={SData[1].HeadingTitle} Desc={SData[1].Desc} ImgSrc={SData[1].ImgSrc} Page={SData[1].Page} keys={SData[1].id} />


    </>
    
  );
}

export default WebsiteDevelopment;