import React from "react";
import PageHeader from "../component/PageHeader";
import SData from "../dataApi/SData";
import ServiceInnerContentSec from "../component/ServiceInnerContentSec";
import {Helmet} from "react-helmet";



function WebsiteDesign() {

  return (
    <>
    <Helmet>
        <title>Website Designing - Fennel Infotech</title>
        <meta name="description" content="Hire Shopify Experts, Wordpress Developer, Andrid and iOS App Developers" />
    </Helmet>

<PageHeader Ptitle="Website Designing" PSubhead="Hire Shopify Experts, Wordpress Developer, Andrid and iOS App Developers" />

<ServiceInnerContentSec HeadingTitle={SData[0].HeadingTitle} Desc={SData[0].Desc} ImgSrc={SData[0].ImgSrc} Page={SData[0].Page} keys={SData[0].id} />


    </>
    
  );
}

export default WebsiteDesign;