import React from "react";
import AccordionData from "../dataApi/AccordionData";
import StepItemData from "../dataApi/StepItemData";

const AccodionCard = (props) => {
    return (
        <>
            <div className="accordion-item">
                <h2 className="accordion-header" id={`heading${props.keys}`} >
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${props.keys}`} aria-expanded="true" aria-controls={`collapse${props.keys}`}>
                        {props.AccorTitle}
                    </button>
                </h2>
                <div id={`collapse${props.keys}`} className={`accordion-collapse collapse  ${props.keys == 1 ? "show" : " "}`} aria-labelledby={`heading${props.keys}`} data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        {props.AccorText}
                    </div>
                </div>
            </div>
        </>
    )
}


const StepItems = (props) => {
    return (
        <>
            <div className="step_item">
                <div className="step_icon"><img src={props.StepImg} /></div>
                <div className="step_title">
                    {props.StepHead}
                </div>
                <div className="step_span_no">{props.keys}</div>
            </div>
        </>
    )
}


const HowWeDo = () => {
    return (
        <>
            <div className="how_we_do_section">
                <div className="container">
                    <div className="row">
                        <h2 className="section_heading"><span>How do we</span> work?</h2>
                    </div>

                    <div className="row">
                        <div className="col-md-6 left_step_section">
                            <div className="step_items">

                            {StepItemData.map(function(StepVal){
                                return(
                                    <>
                                    <StepItems StepHead={StepVal.StepHead} StepImg={StepVal.StepImg} keys={StepVal.id} />
                                    </>
                                )
                            })}
                                

                            </div>
                        </div>

                        <div className="col-md-6 right_accordion_section">
                            <div className="accordion" id="accordionExample">

                                {AccordionData.map(function (AccorVal) {
                                    return (
                                        <>
                                            <AccodionCard AccorTitle={AccorVal.AccorTitle} AccorText={AccorVal.AccorText} keys={AccorVal.id} />
                                        </>
                                    )
                                })}


                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}


export default HowWeDo;