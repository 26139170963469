import React from "react";
import ServiceCardData from "../dataApi/ServiceCardData";


const SerCardItem = (props) => {
    return (
        <>
            <div className="card_item">
                <span className="card_no">{props.keys}</span>
                <div className="cardImg">
                    <img src={props.SerImg} alt="" />
                </div>
                <div className="cardTitle">{props.SerTitle}</div>
                <div className="cardDesc">{props.SerDesc}</div>
            </div>
        </>
    )
}


const ServiceCard = () => {
    return (
        <>
            <div className="container service_card_section">
                <div className="row">
                    <h2 className="section_heading"><span>Our</span> Services</h2>
                    <div className="card_items">
                    {ServiceCardData.map(function(SerVal){
                        return(
<SerCardItem SerImg={SerVal.SerImg} SerTitle={SerVal.SerTitle} SerDesc={SerVal.SerDesc} keys={SerVal.id} />
                 
                        )
                                   })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServiceCard;