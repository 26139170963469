import Banner from '../component/Banner'
import ServiceCard from '../component/ServiceCard';
import HowWeDo from '../component/HowWeDo';
import TabsSection from '../component/TabSection';
import {Helmet} from "react-helmet";

function App() {
  return (
    <>
    <Helmet>
        <title>Shopify Experts, Freelancer Wordpress Developer, Mobile App Developers NYC</title>
        <meta name="description" content="Hire Shopify Experts, Wordpress Developer, Andrid and iOS App Developers" />
    </Helmet>
    <Banner />
    <ServiceCard />
    <HowWeDo />
    <TabsSection />

    </>
    
  );
}

export default App;
