import './App.css';
import Header from './component/NavItemUse';
import FooterSec from './Footer';
import Home from './Pages/Home';
import About from './Pages/About';
import Services from './Pages/Services';
import Contact from './Pages/Contact';
import Career from './Pages/Career';
import WebsiteDesign from './Pages/WebsiteDesign';
import WebsiteDevelopment from './Pages/WebsiteDevelopment';
import InternetMarketing from './Pages/InternetMarketing';
import WebsiteMaintenance from './Pages/WebsiteMaintenance';
import MobileAppDevelopment from './Pages/MobileAppDevelopment';
import EcommerceWebsite from './Pages/EcommerceWebsite';
import {Route, Routes} from "react-router-dom";


function App() {
  return (
    <>
    <Header />
        
    <Routes>
      
    <Route exact path="/" element={<Home />} />
    <Route exact path="/about" element={<About />} />
    <Route exact path="/services" element={<Services />} />
    <Route exact path="/career" element={<Career />} />
    <Route exact path="/contact" element={<Contact />} />
    <Route exact path="/website-designing" element={<WebsiteDesign />} />
    <Route exact path="/website-development" element={<WebsiteDevelopment />} />
    <Route exact path="/internet-marketing" element={<InternetMarketing />} />
    <Route exact path="/mobile-apps-development" element={<MobileAppDevelopment />} />
    <Route exact path="/website-maintanance-services" element={<WebsiteMaintenance />} />
    <Route exact path="/ecommerce-website" element={<EcommerceWebsite />} />
    </ Routes>

    <FooterSec />
    </>
   
    
  );
}

export default App;
