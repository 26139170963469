import { Project1, Project2, Project3, Project4, Project5, Project6, Project7, Project8, Project9, Project10 } from '../images';

const TabsContent = [
        {
            id:"01",
            projectImage:`${Project1}`,
            projectName:"Life with MS and Red",
            projectLink:"https://lifewithmsandred.com/",
            projectCategory:"wordpress"
        },
        {
            id:"02",
            projectImage:`${Project2}`,
            projectName:"Teen Success",
            projectLink:"https://www.teensuccess.com.au/",
            projectCategory:"wordpress"
        },
        {
            id:"03",
            projectImage:`${Project3}`,
            projectName:"Clearview Self Storage",
            projectLink:"https://www.clearviewselfstorage.com.au/",
            projectCategory:"wordpress"
        },
        {
            id:"04",
            projectImage:`${Project4}`,
            projectName:"Props Wholsale",
            projectLink:"https://propswholesale.com/",
            projectCategory:"shopify"
        },
        {
            id:"05",
            projectImage:`${Project5}`,
            projectName:"Affordable Props",
            projectLink:"https://affordableprops.com/",
            projectCategory:"shopify"
        },

        {
            id:"06",
            projectImage:`${Project6}`,
            projectName:"GS Biomark",
            projectLink:"https://gsbiomark.com/",
            projectCategory:"wordpress"
        },
        {
            id:"07",
            projectImage:`${Project7}`,
            projectName:"Unusual Designer Gifts",
            projectLink:"https://unusualdesignergifts.co.uk/",
            projectCategory:"shopify"
        },
        {
            id:"08",
            projectImage:`${Project8}`,
            projectName:"Kindayoung",
            projectLink:"https://kindayoung.com/",
            projectCategory:"shopify"
        },
        {
            id:"09",
            projectImage:`${Project9}`,
            projectName:"Comprosition",
            projectLink:"https://www.comprosition.com/",
            projectCategory:"html"
        },
        {
            id:"10",
            projectImage:`${Project10}`,
            projectName:"Koala Nursery Now",
            projectLink:"http://koalanursery.co.uk/",
            projectCategory:"html"
        }
        


]

export default TabsContent;