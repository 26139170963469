export const LogoImage = require('./logo_image.png');
export const BannerImg = require('./banner_bg.jpg');
export const WebSolution = require('./websolution.png');
export const Ecommerce = require('./ecommerce.png');
export const Wordpress = require('./wordpress.png');
export const Mobile = require('./mobile.png');
export const Marketing = require('./marketing.png');
export const Solution = require('./solution.png');
export const Requirement = require('./requirement.png');
export const Agreement = require('./agreement.png');
export const Workbegin = require('./workbegin.png');
export const Testing = require('./testing.png');
export const Delivery = require('./delivery.png');
export const Support = require('./support.png');




// Our projects
export const Project1 = require('./project1.jpg');
export const Project2 = require('./project2.jpg');
export const Project3 = require('./project3.jpg');
export const Project4 = require('./propswholesale.png');
export const Project5 = require('./affordable.png');
export const Project6 = require('./gsbiomark.png');
export const Project7 = require('./unusual.png');
export const Project8 = require('./kindayoung.png');
export const Project9 = require('./comprosition.png');
export const Project10 = require('./koala.png');





// about page images

export const About1 = require('./about1.jpg');
export const Vision = require('./vision.jpg');
export const Mission = require('./mission.jpg');
export const Objective = require('./objective.jpg');
export const Team = require('./team.jpg');



// service page 

export const WebMaintenance = require('./web-maintenance.png');
export const Development = require('./development.png');

// contact page 
export const Email = require('./email.png');

// Service Inner page 
export const WebDesign = require('./website-design.jpg');
export const WebDevelopment = require('./webdevelopement.jpg');
export const Onlinemarketing = require('./online-marketing.jpg');
export const Androidapp = require('./android-app.jpg');
export const WebMaintain = require('./webmaintain.jpg');
export const Ecommerceinner = require('./ecommerce.jpg');

// Career page 

export const Career = require('./career.jpg');






