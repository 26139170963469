import {WebDesign, WebDevelopment, Onlinemarketing, Androidapp, WebMaintain, Ecommerceinner} from '../images';


const SData = [
    {
        id:"1",
        Page:"Website Designing",
        ImgSrc:`${WebDesign}`,
        HeadingTitle:"Fennel Infotech is a #1 Online Services Provider Company in NYC, Los Angeles, California and Texas.",
        Desc:"If you want to reach or showcase your profile, products and services to multiple customers, regions, cities or countries with low investment, WebsiteDesigning is an integral part for your business either its small or big as day by day Internet users have been increacing rapidly."
    },

    {
        id:"2",
        Page:"Website Development",
        ImgSrc:`${WebDevelopment}`,
        HeadingTitle:"Fennel Infotech is a #1 Online Services Provider Company in NYC, Los Angeles, Washington DC",
        Desc:"You don't want to be dependent on Web Professionals, You want to get your website updation done by your own or you want to create categories or sub-categries and products with details and images by your own. So you should ask for website application development or CMS (Content Management System)"
    },

    {
        id:"3",
        Page:"Online Marketing",
        ImgSrc:`${Onlinemarketing}`,
        HeadingTitle:"Fennel Infotech is a #1 Online Services Provider Company in Delhi India.",
        Desc:"You have got Designed and Developed your dream website but how people will reach to your website who is not aware with you and your busienss. How they will find your products and services on Google/Yahoo/MSN. Through Online Marketing like Google Promotion, Facebook, Twitter etc. in type of tools."
    },

    {
        id:"4",
        Page:"Android Apps Development",
        ImgSrc:`${Androidapp}`,
        HeadingTitle:"Fennel Infotech is a #1 Online Services Provider Company in Delhi India.",
        Desc:"Android is an operating system of Google. Now a days it's very popular and used in Mobile and Tabs. In the android very usefull and excellent features oriented application are available on Google free of cost. And with android application customized application are also."
    },

    {
        id:"5",
        Page:"Website Maintenance Services",
        ImgSrc:`${WebMaintain}`,
        HeadingTitle:"Fennel Infotech is a #1 Online Services Provider Company in Delhi India.",
        Desc:"Dear Sir/Ma'am, you have got designed and developed a website right! But who is caring your website, you are worried that, you have invested so much money to get designed and developed a website but no one is looking after your site as per your requirement no one updating or after delivering your website your previous website company is not offering maintenance services. And you are worried that if you hire a website professional so it costs much more money and you don't require to hire for full time as there is of no use to hire....but if you give contract to any website company so they charge very high or not giving support on time................. soooooooo ooooo ooooooo ooooo don't <br><br>........................worry..........sir/ma'am Fennel Infotech offers your very quick, fast and affordbale website maintenance services to their valued customers. We offer you the website maintenance services in very affordable prices. We never let you feel that we are not a member of your esteemed company. Our always have been an endeavour to provide you best & fast website maintenance services within hour or as per work given by you being your team member. With our top class website maintenance services you enjoy a lot and save your infrastructure, manpower and most valuable thing TIME. We give you maintenance services as per annual maintenance contract (AMC) basis. And give you complete freedom to make payment in easy EMI like Quarterly, Half yearly and Yearly. Let's give an opportunity to serve you............And call us right now at +91-9910203910 to chat with you regarding this and now your problem is solved......................."
    },

    {
        id:"6",
        Page:"eCommerce Website Development",
        ImgSrc:`${Ecommerceinner}`,
        HeadingTitle:"Fennel Infotech is a #1 Online Services Provider Company in Delhi India.",
        Desc:"eCommerce Website is a new trend to sell your products online or through website. With eCommerce website development your whole onine store are created and online payment facility are given as customer can choose the products and pay through debit card."
    }
]

export default SData;