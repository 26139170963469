const AccordionData = [
    {
        id:"1",
        AccorTitle:"Requirement Analysis & Planning",
        AccorText:"Before starting any work of Designing, Web & Mobile Development and Online Marketing, our top priority is to understand our client requirement. We also give our recommendation and what is best for their business. After analysing client's work requirement we do analysis of work for Time and Price, with our team. Its a mandatory part for any work. Doing this we know our objective and work responsibility. If any work is started without understanding customer requirement and doing analysis the final project is neither delivered on time nor with quality."
    },

    {
        id:"2",
        AccorTitle:"Proposal & Agreement Signing",
        AccorText:"After Finalization and Analysis of work we work on proposal and Agreement Signing with our client. Doing agreement is a safe process for both (we & client). In Agreement we describe the complete scope of work, delivery time, costing, payment terms and our legal, terms & conditions. After agreement we both are bound to work together as per agreement. If any party breach the agreement there is a penalty clause. Final agreement is finalized as per Fennel Infotech and Client Satisfaction."
    },

    {
        id:"3",
        AccorTitle:"Work Begin",
        AccorText:"Once Requirement Analysis & Proposal, Agreement work is done and signed. We start the work and giving mockup work. Once the first job is done we send to client and ask for their feedback and approval. If there are any changes we do and again send to client. We never force our client to have any job which is not acceptable for them and not suitable for their client. We always try our level best to give the products and services which is suitable for their client and user friendly. We take all points of SEO as well."
    },

    {
        id:"4",
        AccorTitle:"Testing",
        AccorText:"During the work or module completed we do proper testing by our expert team. If there are any errors in module we rectify and do again testing. Our Testing Work Includes: Code Errors, Security, Speed and Load Times, User behaviour etc. Once our testing is done and error are fixed. We give final demo to our client for testing. Again we rectify and fix the error and show to client. This process help both of us and help us to deliver a quality IT Project. And its reduces time and money."
    },

    {
        id:"5",
        AccorTitle:"Work Delivery",
        AccorText:"After completing all above process we again do testing and fix the errors and deliver to client. Finally, now its time for launch so we ask for server detail to make online the project. If client is not sure which domain or server to purchase so we offer the same services and provide consultancy pertaining to the same. Once server process is done we launch the project and congrats to our customer and give them assurity that it will be a great and perfect running project which will come true your business dream and generate good business."
    },
    {
        id:"6",
        AccorTitle:"Support",
        AccorText:"Once the delivered the project, you are not alone. After delivery customer still required support and solutions. In one shot any client can not describe the work. So, in support we take care all minor issues for next 3,6 and 12 Months and fix. If any additional job is arises or suggested by client we do happily. Consequently, after delivery the job you are not alone. We support you as an IT Member of your company. We never hesitate to support for any type of work."
    }

]



export default AccordionData;