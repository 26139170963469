import {WebSolution, Ecommerce, Wordpress, Mobile, Marketing, Solution} from '../images';


const ServiceCardData = [
    {
        id:"01",
        SerImg: `${WebSolution}`,
        SerTitle:"Website Solutions",
        SerDesc:"Responsive Website, Web Application, PSD to HTML, Website Maintenance"
    },
    {
        id:"02",
        SerImg: `${Ecommerce}`,
        SerTitle:"eCommerce Soultions",
        SerDesc:"eCommerce Website, Payment Gateway, Products Data Entry, Annual Maintenance"
    },
    {
        id:"03",
        SerImg: `${Wordpress}`,
        SerTitle:"Open Source Application",
        SerDesc:"WordPress, Drupal, Square Space, WooCommerce, Magento, Shopify"
    },
    {
        id:"04",
        SerImg: `${Mobile}`,
        SerTitle:"Mobile Apps Development",
        SerDesc:"Android Apps, iOS Apps, Hybrid Cross Platform Apps"
    },
    {
        id:"05",
        SerImg: `${Marketing}`,
        SerTitle:"Digital Marketing",
        SerDesc:"SEO Services, Backlink Building, PPC / Google Adwords, Social Media Marketing"
    },
    {
        id:"06",
        SerImg: `${Solution}`,
        SerTitle:"Creative Solutions",
        SerDesc:"Web Page Designing, Mailer Designing, Logo Designing"
    }
]


export default ServiceCardData;