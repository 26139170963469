import React from "react";
import {Career} from '../images';

const CareerContent = () => {
    return(
        <>
        <div className="container career_section">
                <div className="row career_content align-items-center">
                    
                        <div className="careerImage col-md-6">
                            <img src={Career} alt="" className="w-100" />
                        </div>
                        <div className="career_text col-md-6">
                            <h2>Fennel Info Tech- Reach Higher, Achieve More</h2>
                            <p>We believe in encouraging and enriching individual and his skill-set. We understand that any enterprise can deliver best results and attain goals if it has the right people, the right skill set, zeal to achieve and fortitude to succeed.</p>
                            <p>From the day you join us, we ensure that you get on the path of growth and success. For us employee is first. We believe if our employee is happy and content, only then we can deliver the best to our clients and make them happy.</p>
                        </div>
                   
                </div>
            </div>

            <div className="career_call_to_action">
                <h2>For a bright and dynamic future, simply mail your CV</h2>
                <a href="mailto:hr@fennelinfotech.com">hr@fennelinfotech.com</a>
            </div>
        </>
    )
}

export default CareerContent;