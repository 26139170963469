import React from "react";
import { BannerImg } from '../images';
import { Link } from "react-router-dom";

const style = {
    background: `url(${BannerImg})`,

}

function Banner() {
    return (
        <>

            <div className="bannerSection" style={style}>
                <div className="container py-5">
                    <div className="row align-items-center">
                        <div className="col-md-12 text-center">
                            <div className="banner_content">
                                <h2>Hire Best Indian Web Design and Digital Marketing Agency</h2>
                                <h3>Shopify Experts, WordPress Web Designer & Developer, SEO Agency, and Android / iOS Mobile App Developers</h3>
                                <Link to={`${process.env.PUBLIC_URL}/contact`} className="btn banner_btn">
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
}

export default Banner;