import React from "react";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TabsContent from "../dataApi/TabsContent";


const Projects = (props) => {
    return(
        <>
        
            <div className="project_item">
                    <img src={props.projectImage} alt="" />

                    <a href={props.projectLink} target ="_blank" className="project_title">{props.projectName}</a>
                </div>
        </>
    )
}

const TabsSection = () => {
    return (
        <>
            <div className="container tabs_content_section">
                <div className="row">
                    <div className="col-md-12">
                    <h2 className="section_heading"><span>Our</span> Projects</h2>
                        </div>
                
                    <Tabs defaultActiveKey="all" id="uncontrolled-tab-example" className="mb-3">
                        <Tab eventKey="all" title="All">
                        <div class="project_items">
                            {TabsContent.map(function(TabsVal){
                                return(
                                    <>
                                   
                                    <Projects projectImage={TabsVal.projectImage} projectName={TabsVal.projectName} projectLink={TabsVal.projectLink} />
                                    
                                    </>
                                    
                                )
                            })}

                            </div>
                                
                        </Tab>

                        <Tab eventKey="wordpress" title="Wordpress">
                        <div class="project_items">
                        {TabsContent.map(function(TabsVal){
                            if(TabsVal.projectCategory == "wordpress"){
                                return(
                                    <>
                                    
                                    <Projects projectImage={TabsVal.projectImage} projectName={TabsVal.projectName} projectLink={TabsVal.projectLink} />
                                    
                                    </>
                                    
                                )
                            }
                               
                            })}
                            </div>
                        </Tab>
                        <Tab eventKey="shopify" title="Shopify">
                        <div class="project_items">
                        {TabsContent.map(function(TabsVal){
                            if(TabsVal.projectCategory == "shopify"){
                                return(
                                    <>
                                     
                                    <Projects projectImage={TabsVal.projectImage} projectName={TabsVal.projectName} projectLink={TabsVal.projectLink} />
                                 
                                    </>
                                    
                                )
                            }
                               
                            })}
                            </div>
                        </Tab>
                        <Tab eventKey="htmlCss" title="HTML/CSS">
                        <div class="project_items">
                        {TabsContent.map(function(TabsVal){
                            if(TabsVal.projectCategory == "html"){
                                return(
                                    <>
                                    
                                    <Projects projectImage={TabsVal.projectImage} projectName={TabsVal.projectName} projectLink={TabsVal.projectLink} />
                                    
                                    </>
                                    
                                )
                            }
                               
                            })}
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>

        </>
    )
}

export default TabsSection;