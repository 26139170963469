import React from "react";
import PageHeader from "../component/PageHeader";
import OurServicesCard from '../component/OurServicesCard';
import {Helmet} from "react-helmet";

function Services() {
  return (
    <>
    <Helmet>
        <title>Services - Fennel Infotech</title>
        <meta name="description" content="Hire Shopify Experts, Wordpress Developer, Andrid and iOS App Developers" />
    </Helmet>

<PageHeader Ptitle="Our Services" PSubhead="Fennel Infotech - is an emerging and promising online services provider company in NYC, Los Angeles, Washington DC." />
<OurServicesCard />
    </>
    
  );
}

export default Services;