import {WebSolution, Ecommerce, WebMaintenance, Mobile, Marketing, Development} from '../images';


const ServicePageCardData = [
    {
        id:"01",
        SerImg: `${WebSolution}`,
        SerTitle:"Website Designing",
        SerDesc:"If you want to reach or showcase your profile, products and services to multiple customers, regions, cities or countries.",
        SerUrl:"website-designing"
    },
    {
        id:"02",
        SerImg: `${Development}`,
        SerTitle:"Website Development",
        SerDesc:"You don't want to be dependent on Web Professionals, You want to get your website updation done by your own or you",
        SerUrl:"website-development"
    },
    {
        id:"03",
        SerImg: `${Marketing}`,
        SerTitle:"Online/Internet Marketing",
        SerDesc:"You have got Designed and Developed your dream website but how people will reach to your website.y",
        SerUrl:"internet-marketing"
    },
    {
        id:"04",
        SerImg: `${Mobile}`,
        SerTitle:"Mobile Apps Development",
        SerDesc:"Android is an operating system of Google. Now a days it's very popular and used in Mobile and Tabs.",
        SerUrl:"mobile-apps-development"
    },
    {
        id:"05",
        SerImg: `${WebMaintenance}`,
        SerTitle:"Website Maintenance Services",
        SerDesc:"Dear Sir/Ma'am, you have got designed and developed a website right! But who is caring your website.",
        SerUrl:"website-maintanance-services"
    },
    {
        id:"06",
        SerImg: `${Ecommerce}`,
        SerTitle:"eCommerce Website Dev.",
        SerDesc:"eCommerce Website is a new trend to sell your products online or through website. With eCommerce website.",
        SerUrl:"ecommerce-website"
    }
]


export default ServicePageCardData;