const NavbarMenu = [
    {
        id:"1",
        NavLink:"Home",
        NavUrl: ""
    },
    {
        id:"2",
        NavLink:"About Us",
        NavUrl: "about"
    },
    {
        id:"3",
        NavLink:"Our Services",
        NavUrl: "services"
    },
    {
        id:"4",
        NavLink:"Career",
        NavUrl: "career"
    },
    {
        id:"5",
        NavLink:"Contact Us",
        NavUrl: "contact"
    }
]

export default NavbarMenu;