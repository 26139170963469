import React from "react";
import { LogoImage } from './images';
import NavbarMenu from "./dataApi/NavbarMenu";
import {Link} from "react-router-dom";

const NavItem = (props) => {
    return(
        <>
        <li className="nav-item">
        <Link to={`${process.env.PUBLIC_URL}/${props.NavUrl}`} className="nav-link">
        {props.NavLink}
        </Link>
         
        </li>
        </>
    )
}

const FooterSec = () => {
    return (
        <>
            <footer id="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 footer_about">
                            <a className="navbar-brand" href="#"><img src={LogoImage} /></a>
                            <p>
                            Fennel InfoTech is a reputed IT service provider firm based in NYC since 2008. As a leading name in the industry, we always commit to serve you exceptionally.
                            </p>

                        </div>

                        <div className="col-md-4 footer_menu">
                            <h2 className="footer_heading">
                                    Quick Links
                                </h2>
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                                {NavbarMenu.map(function (navVal) {
                                    return (
                                        <NavItem NavLink={navVal.NavLink} NavUrl={navVal.NavUrl} key={navVal.id} />
                                    )

                                })}



                            </ul>
                        </div>

                        <div className="col-md-4 footer_contact">
                        <h2 className="footer_heading">
                                    Contact Details
                                </h2>
                                <ul>
                                    <li><a href="mailto:sales@fennelinfotech.com">sales@fennelinfotech.com</a></li>
                                    <li><a href="mailto:support@fennelinfotech.com">support@fennelinfotech.com</a></li>
                                    <li><a href="mailto:hr@fennelinfotech.com">hr@fennelinfotech.com</a></li>
                                </ul>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                                <div className="container">
                                    <div className="row">
                                        <p>Â© 2017 FennelInfotech.com</p>
                                        </div>
                                        </div>
                    </div>
            </footer>
        </>
    )
}

export default FooterSec;