import { LogoImage } from '../images';
import { NavLink } from "react-router-dom";

const HeaderFile = ({menuData}) => {
    return (
        <>
            <header className="header">
                <nav className="navbar navbar-expand-lg navbar-dark">
                    <div className="container">
                        <a className="navbar-brand" href="/"><img src={LogoImage} /></a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                {menuData.map(function (navVal) {
                                    return (
                                        <li className="nav-item" key={navVal.id}>
                                            <NavLink activeClassName="active" to={`${process.env.PUBLIC_URL}/${navVal.NavUrl}`} className="nav-link">
                                                {navVal.NavLink}
                                            </NavLink>

                                        </li>


                                    )

                                })}



                            </ul>

                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}


export default HeaderFile;