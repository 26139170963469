import React from "react";



const PageHeader = (props) => {
    return (
        <div className="page_header_section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">

                        <h2 className="page_title">
                            {props.Ptitle}
                        </h2>
                        <p className="page_subheading">
                            {props.PSubhead}
                        </p>



                    </div>
                </div>
            </div>
        </div>
    )

}

export default PageHeader;