import React from "react";
import PageHeader from "../component/PageHeader";
import SData from "../dataApi/SData";
import ServiceInnerContentSec from "../component/ServiceInnerContentSec";
import {Helmet} from "react-helmet";



function MobileAppDevelopment() {

  return (
    <>
    <Helmet>
        <title>Android Apps Development - Fennel Infotech</title>
        <meta name="description" content="Hire Shopify Experts, Wordpress Developer, Andrid and iOS App Developers" />
    </Helmet>

<PageHeader Ptitle="Android Apps Development" PSubhead="Fennel Infotech - is an emerging and promising online services provider company." />

<ServiceInnerContentSec HeadingTitle={SData[3].HeadingTitle} Desc={SData[3].Desc} ImgSrc={SData[3].ImgSrc} Page={SData[3].Page} keys={SData[3].id} />


    </>
    
  );
}

export default MobileAppDevelopment;