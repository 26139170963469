import React, { useState } from "react";
import NavbarMenu from "../dataApi/NavbarMenu";
import HeaderFile from "../component/HeaderFile";

const Header = () => {
const [menuData, setMenuData] = useState(NavbarMenu);
    return(
<>
<HeaderFile menuData={menuData} />
</>
    )
}

export default Header;