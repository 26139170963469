import React from "react";
import PageHeader from "../component/PageHeader";
import SData from "../dataApi/SData";
import ServiceInnerContentSec from "../component/ServiceInnerContentSec";
import {Helmet} from "react-helmet";



function WebsiteMaintenance() {

  return (
    <>
    <Helmet>
        <title>Website Maintenance Services - Fennel Infotech</title>
        <meta name="description" content="Hire Shopify Experts, Wordpress Developer, Andrid and iOS App Developers" />
    </Helmet>

<PageHeader Ptitle="Website Maintenance Services" PSubhead="Fennel Infotech - is an emerging and promising online services provider company." />

<ServiceInnerContentSec HeadingTitle={SData[4].HeadingTitle} Desc={SData[4].Desc} ImgSrc={SData[4].ImgSrc} Page={SData[4].Page} keys={SData[4].id} />


    </>
    
  );
}

export default WebsiteMaintenance;