import React from "react";
import PageHeader from "../component/PageHeader";
import SData from "../dataApi/SData";
import ServiceInnerContentSec from "../component/ServiceInnerContentSec";
import {Helmet} from "react-helmet";




function EcommerceWebsite() {

  return (
    <>
    <Helmet>
        <title>Ecommerce Website - Fennel Infotech</title>
        <meta name="description" content="Hire Shopify Experts, Wordpress Developer, Andrid and iOS App Developers" />
    </Helmet>

<PageHeader Ptitle="eCommerce Website Development" PSubhead="Fennel Infotech - is an emerging and promising online services provider company." />
<ServiceInnerContentSec HeadingTitle={SData[5].HeadingTitle} Desc={SData[5].Desc} ImgSrc={SData[5].ImgSrc} Page={SData[5].Page} keys={SData[5].id} />


    </>
    
  );
}

export default EcommerceWebsite;