import { Requirement , Agreement, Workbegin, Testing, Delivery, Support } from '../images';

const StepItemData = [
    {
        id:"01",
        StepImg: `${Requirement}`,
        StepHead:"Requirement"
    },

    {
        id:"02",
        StepImg: `${Agreement}`,
        StepHead:"Agreement"
    },

    {
        id:"03",
        StepImg: `${Workbegin}`,
        StepHead:"Workbegin"
    },

    {
        id:"04",
        StepImg: `${Testing}`,
        StepHead:"Testing"
    },

    {
        id:"05",
        StepImg: `${Delivery}`,
        StepHead:"Delivery"
    },

    {
        id:"06",
        StepImg: `${Support}`,
        StepHead:"Support"
    }
]


export default StepItemData;