import React from "react";
import { Email } from "../images";
import emailjs from 'emailjs-com';


function sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm('service_u1oqexb', 'template_843cs4w', e.target, 'QtPQEWf_YxHP6ptU-')
    .then((result) => {
    console.log(result.text);
    }, (error) => {
    console.log(error.text);
    });
    e.target.reset();   
    }

const ContactPageContent = () => {
    return (
        <>
            <div className="container contact_section">
                <div className="row contact_content align-items-center">

                    <div className="contact_details col-md-6">
                        <div className="email_icon">
                            <img src={Email} alt="" className="w-100" />
                        </div>
                        <div className="email_ids">
                            <a href="mailto:sales@fennelinfotech.com">sales@fennelinfotech.com</a>
                            <a href="mailto:support@fennelinfotech.com">support@fennelinfotech.com</a>
                            <a href="mailto:hr@fennelinfotech.com">hr@fennelinfotech.com</a>
                        </div>
                    </div>
                    <div className="contact_form col-md-6">
                        <h2>Get in touch</h2>
                        <form onSubmit={sendEmail}>

                            <input required type="text" className="form-control" id="exampleInputName" aria-describedby="textHelp" placeholder="Full Name" name="name" />

                            <input required type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" name="email"/>

                            <input required type="number" className="form-control" id="exampleInputPhone" placeholder="Phone" name="phone" />
                            <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" name="message"></textarea>

                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                    </div>

                </div>
            </div>


        </>
    )
}

export default ContactPageContent;