import {About1, Vision, Mission, Objective, Team} from '../images';

const AboutPageData = [
    {
        id: "1",
        Heading: "Fennel InfoTech-Your Digital Partner",
        Text: "<p>Fennel InfoTech is a reputed IT service provider firm based in NYC since 2008. As a leading name in the industry, we always commit to serve you exceptionally.</p> <p>We provide feature rich services in Web Designing and Development, Mobile Application Development and Search Engine Optimization. In addition, we provide services in content writing, website maintenance, Domain and Hosting Registration</p><p>In this cut-throat market, our ultimate objective is to promote an overall growth of a client’s business. A strong foothold of inventive ideas and a knack to deliver products with extreme perfection is what drive us stridently in this global market.</p> <p>Our Online Service Provider Company in India has provided best affordable services to numerous medium and large sized enterprises.</p>",
        ImgSrc: `${About1}`
    },

    {
        id: "2",
        Heading: "Our Vision",
        Text: "<p>We strongly believe in timely delivery and cost-effective solutions along with uniformity and matchless quality. We have inspired to offer state of the art web developing solutions to our global clients.</p>",
        ImgSrc: `${Vision}`
    },

    {
        id: "3",
        Heading: "Our Mission",
        Text: "<p>Fennel Infotech has a clear mission is to focus on speedup the business growth of our customers with high-quality and creative Design and Development and build a connection and interaction between our clients for long term & strong relationship with us.</p>",
        ImgSrc: `${Mission}`
    },

    {
        id: "4",
        Heading: "Our Objective",
        Text: "<ul><li>Is to provide a timely solution.</li><li>Is to be truly concerned in seeing for our clients succeed in Market.</li><li>Is to love and believe to use data & technology and provide high range of services.</li><li>Is to be ready to faces business challenges for create positive outcomes for our clients.</li><li>Is to be primary focus on Functionality, Flexibility, and Usability of the websites.</li></ul>",
        ImgSrc: `${Objective}`
    },

    {
        id: "5",
        Heading: "Our Team",
        Text: "<p>We excel a team of expert web designers, android app developers, Search Engine Optimizers and other IT members. Our dedicated team always emphasize on offering quality task using latest technologies. Our team possess sound experience, knowledge and skills to deliver best. They utilize creative ideas to design and develop new things.</p><p>Fennel InfoTech provide equal opportunity to all employees. For us, the employee is always first. We always give our best to develop leadership qualities in all individuals.</p>",
        ImgSrc: `${Team}`
    }
]

export default AboutPageData;