import React from "react";
import PageHeader from "../component/PageHeader";
import CareerContent from "../component/CareerContent";
import {Helmet} from "react-helmet";

function Career() {
  return (
    <>
    <Helmet>
        <title>Career - Fennel Infotech</title>
        <meta name="description" content="Hire Shopify Experts, Wordpress Developer, Andrid and iOS App Developers" />
    </Helmet>
<PageHeader Ptitle="Career" PSubhead="Fennel Infotech- is an emerging and promising online services provider company in NYC, Los Angeles." />
<CareerContent />
    </>
    
  );
}

export default Career;