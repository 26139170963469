import React from "react";
import PageHeader from "../component/PageHeader";
import ContactPageContent from "../component/ContactPageContent";
import {Helmet} from "react-helmet";

function Contact() {
  return (
    <>
    <Helmet>
        <title>Contact - Fennel Infotech</title>
        <meta name="description" content="Hire Shopify Experts, Wordpress Developer, Andrid and iOS App Developers" />
    </Helmet>
    
<PageHeader Ptitle="Contact Us" PSubhead="Fennel Infotech - is an emerging and promising online services provider company." />
<ContactPageContent />
    </>
    
  );
}

export default Contact;