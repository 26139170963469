import React from "react";
import {Link} from "react-router-dom";

const ServiceInnerContentSec = (props) => {
    return (
        <div className="container servicePage_content">
            <div className="row">
            <div className="col-md-6 image_sec">
                    <img src={props.ImgSrc} alt={props.Page} className="w-100" />
                </div>
                <div className="col-md-6 content_sec">
                    <h2>{props.HeadingTitle}</h2>
                    <div className="content">
                    <div dangerouslySetInnerHTML={{__html: `<div className="service_text"> ${props.Desc} </div>`}}></div>
                    <Link to={`${process.env.PUBLIC_URL}/contact`}>
                    Contact Us
                    </Link>
                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceInnerContentSec;